:root[color-theme="dark"] {
  --bg-main: #333D4F;
  --bg-main-alt: #2e3747;
  --bg-panel: #1f252f;
  --bg-button: #585f70;

  --color-main: #f1f2f5;
  --color-alt: #A3ABBD;
}
:root[color-theme="light"] {
  --bg-main: #ffffff;
  --bg-main-alt: #fAfAfB;
  --bg-panel: #505865;
  --bg-button: #ECF0F5;

  --color-main: #333D4F;
  --color-alt: #959faf;
}

body{margin:0;padding:0;background-color:var(--bg-main);color:var(--color-main);font-family:Montserrat;font-style:normal;}
input{width:100%;display:block;min-width:0;border:none;outline:0;padding:0;color:var(--color-main);background-color: transparent;}
textarea{border:none;overflow:auto;outline:none;box-shadow:none;resize:none;color:var(--color-main);background-color:transparent;}
textarea::placeholder{color:var(--color-alt);}

.border-bottom {border-bottom: 2px solid var(--color-main)}
.border-bottom-letters {
  background: repeating-linear-gradient(90deg, var(--color-main) 0, var(--color-main) 1ch, transparent 0, transparent 1.5ch) 0px 100% / 11.5ch 2px no-repeat;
  letter-spacing: 0.5ch;
}
.bg-panel{background-color:var(--bg-panel)}
.bg-main{background-color:var(--bg-main)}
.bg-main-alt{background-color:var(--bg-main-alt)}
.bg-inverted{background-color:var(--color-main)}
.bg-button{background-color:var(--bg-button)}
.color-alt{color: var(--color-alt)}
.color-main{color: var(--color-main)}
.color-inverted{color: var(--bg-main)}
.border{border:1px solid var(--color-main)}
.border-dashed{border:1px dashed var(--color-main)}
.border-alt{border:1px solid var(--color-alt)}
.corner-gradient{background: linear-gradient(170deg, var(--bg-main), var(--bg-main), var(--bg-button))}
.hover-bg-main:hover {background-color:var(--bg-main)}

html{scroll-behavior:smooth;font-size:10px}
.landingtile{padding: 20px; margin-left: 0px; width: 304px; height: 370px; margin-bottom: 30px; border-radius: 21px; box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1)}
@media only screen and (min-width:600px){
html{font-size:12px;
}
.landingtile{margin-left: 21px;}

.anti-scroll-jump{
  margin-left: calc(100vw - 100%);
  margin-right: 0;
  overflow: auto;
}

}

.editor-width {
	max-width: 700px;
}
@media only screen and (min-width:1500px){
	.editor-width {
		max-width: 1000px;
	}
}
div {
    -webkit-tap-highlight-color: transparent;
    outline: none;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
}

.segoe{font-family:'Open Sans',sans-serif;}
button, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}
ul {  
  list-style-type: none;
  padding: 0;
  margin: 0;
}  

a{color:inherit;text-decoration:inherit}
.row{display:flex;flex-direction:row}
.column{display:flex;flex-direction:column}
::placeholder{color:#cacaca}
.landing::placeholder{color:#000}
.button{box-shadow:0 2px 4px rgba(0,0,0,.25)}
.button:active{box-shadow:0 1px 1px rgba(0,0,0,.25);transform:translateY(4px)}
.button.active{box-shadow:0 1px 1px rgba(0,0,0,.25);transform:translateY(4px)}
.white{color:#fff}
.black{color:#000}
.inactivetab{color: #C9C9C9}
.gray{color:#505865}
.blue{color:#00889b}
.opacited{opacity: 0.5}
.error{color:#ffafbf}
.lightgray{color:#999}
.flex1{flex:1}
.flex2{flex:2}
.italic{font-style:italic}


.light\~ {color: white} @media (prefers-color-scheme: dark) { .light\~ {color: #333D4F}}
.backlight\~ {background-color: white} @media (prefers-color-scheme: dark) { .backlight\~ {background-color: #333D4F}}
.dark\~ {color: #333D4F} @media (prefers-color-scheme: dark) {	.dark\~ {color: white}}
.backdark\~ {background-color: #333D4F} @media (prefers-color-scheme: dark) { .backdark\~ {background-color: white}}
.borderdark\~ {border: 2px solid #333D4F} @media (prefers-color-scheme: dark) { .bottomdark\~ {border: 2px solid white}}

/* REPLACE NATIVE CHECKBOX */
input[type="checkbox"] {
	appearance: none;
	/* For iOS < 15 to remove gradient background */
	background-color: #fff;
	/* Not removed via appearance */
	margin: 0;
	font: inherit;
	color: #505865;
	width: 1.5em;
	height: 1.5em;
	border: 0.15em solid #505865;
	border-radius: 0.15em;
	transform: translateY(-0.075em);
	display: grid;
	place-content: center;
}
input[type="checkbox"]::before {
	content: "";
	width: 13px;
	height: 13px;
	transform: scale(0);
	transition: 100ms transform ease-in-out;
	box-shadow: inset 1em 1em #505865;
}
input[type="checkbox"]:checked::before {
	transform: scale(1);
}
input[type="checkbox"]:disabled {
	--form-control-color: #C9C9C9;
	background-color: #f3f5f9;
	color: #C9C9C9;
}

.top2{margin-top:.2rem}
.top3{margin-top:.3rem}
.top5{margin-top:.5rem}
.top7{margin-top:7px}
.top10{margin-top:10px}
.top13{margin-top:13px}
.top15{margin-top:1.5rem}
.top20{margin-top:2rem}
.bottom10{margin-bottom:1rem}
.bottom20{margin-bottom:2rem}
.top35{margin-top:3.5rem}
.top48{margin-top:4.8rem}
.top50{margin-top:5rem}
.top70{margin-top:7rem}
.left1{margin-left:.1rem}
.left5{margin-left:.5rem}
.left10{margin-left:1rem}
.left15{margin-left:1.5rem}
.left50{margin-left:5rem}
.right3{margin-right:.3rem}
.right5{margin-right:.5rem}
.right10{margin-right:1rem}
.right15{margin-right:1.5rem}

.scale98{transform: scale(0.98);}

.backwhite{background-color:#fff}
.backg{background-color:#E0E3E8}

.backgrayblue{background-color:#677a9a}
.backdebug{background-color:#00ff0022}
.semibold{font-weight:600}
.bold{font-weight:700}
.italic{font-style:italic}
.border2-white{border:2px solid #fff}
.border1-black{border:1px solid #000}
.border1-blue{border:1px solid #00889b}
.border1-grayblue{border:1px solid #677a9a}
.tab-border1{border-bottom:1px solid #bfbfbf}
.up-border1{border-top:1px solid #e8e8e8}
.pointer{cursor:pointer}
.hovered {background-color: #D2DAE350 !important; }
.hoverable:hover {background-color: #D2DAE350 !important; }
.hoverwhite:hover {background-color: #F7F8FB40 !important; }
.shadow{filter:drop-shadow(0 4px 2px rgba(0, 0, 0, .25))}
.selectedshadow{box-shadow: 0px 1px 6px #505865; z-index: 7;position: relative;}
.backdark{background-color:#00000050}
.tab:after{content:"";position:absolute;bottom:-1px;left:0;height:3px;width:100%}
.tab.white:after{background-color:#fff}
.tab.gray:after{background-color:#555}
.nowrap{white-space:nowrap}
.nowrapdots{text-overflow:ellipsis;overflow:hidden;white-space:nowrap}
::-webkit-scrollbar{width:4px;height:4px}
::-webkit-scrollbar-button{height:0}
::-webkit-scrollbar-thumb{background:#bbb}
.scroller { scrollbar-width: thin;scrollbar-color: #bbb transparent;}
.hide-scroll::-webkit-scrollbar {	display: none;  }
.hide-scroll {-ms-overflow-style: none;  /* IE and Edge */scrollbar-width: none;  /* Firefox */  }
.textCenter{text-align:center}
.textRight{text-align:right}
.slick-prev:before,
.slick-next:before {
  color: #677a9a!important;
  font-size: 40px!important;
}
.slick-prev,
.slick-next {
  width: 40px!important;
  height: 40px!important;
}
:root{
	--toastify-color-dark: #505865 !important;
	--toastify-color-progress-dark: #DDC80A !important;
	--toastify-toast-width: 520px !important;
	--toastify-font-family: Montserrat !important;
}
@media only screen and (max-width:500px) {
	.Toastify__toast-container--bottom-center {
		bottom: 90px !important;
	}
}
.st4 {
	fill: currentColor;
  }
@keyframes growFromZero {
	from {
	  transform: scale(0.5);
	}
	to {
	  transform: scale(1);
	}
  }
  @keyframes grow {
	from {
	  transform: scale(1);
	}
	to {
	  transform: scale(1.2);
	}
  }

@keyframes slide-in-up {
    0% { transform: translateY(100%); }
    100% { transform: translateY(0%); }
}

@keyframes slide-in-left {
    0% { transform: translateX(100%); }
    100% { transform: translateX(0%); }
}

/* Hide Arrows From Input Number */
input::-webkit-outer-spin-button,input::-webkit-inner-spin-button { -webkit-appearance: none; margin: 0;} /* Chrome, Safari, Edge, Opera */
input[type=number] {  -moz-appearance: textfield;} /* Firefox */


.error-blink {
    color: black;
    animation: blink 0.6s;
    animation-iteration-count: 2;
}
@keyframes blink { 50% { color:#fff ; }  }


.show-after-delay {
  animation: toVisibility 0s 500ms forwards;
  visibility: hidden;
}
@keyframes toVisibility {
  to   { visibility: visible; }
}
/* preloader */
.lds-ellipsis {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
  }
  .lds-ellipsis div {
	position: absolute;
	top: 33px;
	width: 13px;
	height: 13px;
	border-radius: 50%;
	background: #999;
	animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
	left: 8px;
	animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
	left: 8px;
	animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
	left: 32px;
	animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
	left: 56px;
	animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
	0% {
	  transform: scale(0);
	}
	100% {
	  transform: scale(1);
	}
  }
  @keyframes lds-ellipsis3 {
	0% {
	  transform: scale(1);
	}
	100% {
	  transform: scale(0);
	}
  }
  @keyframes lds-ellipsis2 {
	0% {
	  transform: translate(0, 0);
	}
	100% {
	  transform: translate(24px, 0);
	}
  }
/*end*/  


.particle {
    position: absolute;
	width: 0px;
	height: 0px;
	animation-name: move;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
	color: #747E8E;
}

  @keyframes move {
	100% {
	  transform: translate3d(0, 0, 1px) rotate(360deg);
	}
  }

@keyframes fly-right {
	0% { transform: translateX(0); }
	10% { transform: translateX(-15px); }
    99% { transform: translateX(200px) translateY(-40px) rotate(-20deg) scale(0.1); }
    100% { transform: translateX(200px) translateY(-40px) rotate(-20deg) scale(0.1); visibility: hidden; }
}
@keyframes shake {
	10%, 90% {
	  transform: translate3d(-1px, 0, 0);
	}
	20%, 80% {
	  transform: translate3d(2px, 0, 0);
	}
	30%, 50%, 70% {
	  transform: translate3d(-4px, 0, 0);
	}
	40%, 60% {
	  transform: translate3d(4px, 0, 0);
	}
  }

@keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0;
    }
    1% {
        display: block;
        opacity: 0;
    }
    100% {
        display: block;
        opacity: 1;
    }
}

@keyframes fadeInInTheEnd {
    0% {
        display: none;
        opacity: 0;
    }
    50% {
        display: none;
        opacity: 0;
    }
    51% {
        display: block;
        opacity: 0;
    }
    100% {
        display: block;
        opacity: 1;
    }
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 22px;
  height: 22px;
}
.loader {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 5px solid rgba(255, 255, 255, 0.2);
  border-right: 5px solid rgba(255, 255, 255, 0.2);
  border-bottom: 5px solid rgba(255, 255, 255, 0.2);
  border-left: 5px solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 0.9s infinite linear;
  animation: load8 0.9s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
