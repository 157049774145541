.mtop1{margin-top:1px}
.mtop2{margin-top:2px}
.mtop3{margin-top:3px}
.mtop4{margin-top:4px}
.mtop5{margin-top:5px}
.mtop6{margin-top:6px}
.mtop7{margin-top:7px}
.mtop8{margin-top:8px}
.mtop9{margin-top:9px}
.mtop10{margin-top:10px}
.mtop11{margin-top:11px}
.mtop12{margin-top:12px}
.mtop13{margin-top:13px}
.mtop14{margin-top:14px}
.mtop15{margin-top:15px}
.mtop16{margin-top:16px}
.mtop17{margin-top:17px}
.mtop18{margin-top:18px}
.mtop19{margin-top:19px}
.mtop20{margin-top:20px}
.mtop21{margin-top:21px}
.mtop22{margin-top:22px}
.mtop23{margin-top:23px}
.mtop24{margin-top:24px}
.mtop25{margin-top:25px}
.mtop26{margin-top:26px}
.mtop27{margin-top:27px}
.mtop28{margin-top:28px}
.mtop29{margin-top:29px}
.mtop30{margin-top:30px}
.mtop31{margin-top:31px}
.mtop32{margin-top:32px}
.mtop33{margin-top:33px}
.mtop34{margin-top:34px}
.mtop35{margin-top:35px}
.mtop36{margin-top:36px}
.mtop37{margin-top:37px}
.mtop38{margin-top:38px}
.mtop39{margin-top:39px}
.mtop40{margin-top:40px}
.mtop41{margin-top:41px}
.mtop42{margin-top:42px}
.mtop43{margin-top:43px}
.mtop44{margin-top:44px}
.mtop45{margin-top:45px}
.mtop46{margin-top:46px}
.mtop47{margin-top:47px}
.mtop48{margin-top:48px}
.mtop49{margin-top:49px}
.mtop50{margin-top:50px}
.mtop51{margin-top:51px}
.mtop52{margin-top:52px}
.mtop53{margin-top:53px}
.mtop54{margin-top:54px}
.mtop55{margin-top:55px}
.mtop56{margin-top:56px}
.mtop57{margin-top:57px}
.mtop58{margin-top:58px}
.mtop59{margin-top:59px}
.mtop60{margin-top:60px}
.mtop61{margin-top:61px}
.mtop62{margin-top:62px}
.mtop63{margin-top:63px}
.mtop64{margin-top:64px}
.mtop65{margin-top:65px}
.mtop66{margin-top:66px}
.mtop67{margin-top:67px}
.mtop68{margin-top:68px}
.mtop69{margin-top:69px}
.mtop70{margin-top:70px}

.mbottom1{margin-bottom:1px}
.mbottom2{margin-bottom:2px}
.mbottom3{margin-bottom:3px}
.mbottom4{margin-bottom:4px}
.mbottom5{margin-bottom:5px}
.mbottom6{margin-bottom:6px}
.mbottom7{margin-bottom:7px}
.mbottom8{margin-bottom:8px}
.mbottom9{margin-bottom:9px}
.mbottom10{margin-bottom:10px}
.mbottom11{margin-bottom:11px}
.mbottom12{margin-bottom:12px}
.mbottom13{margin-bottom:13px}
.mbottom14{margin-bottom:14px}
.mbottom15{margin-bottom:15px}
.mbottom16{margin-bottom:16px}
.mbottom17{margin-bottom:17px}
.mbottom18{margin-bottom:18px}
.mbottom19{margin-bottom:19px}
.mbottom20{margin-bottom:20px}
.mbottom21{margin-bottom:21px}
.mbottom22{margin-bottom:22px}
.mbottom23{margin-bottom:23px}
.mbottom24{margin-bottom:24px}
.mbottom25{margin-bottom:25px}
.mbottom26{margin-bottom:26px}
.mbottom27{margin-bottom:27px}
.mbottom28{margin-bottom:28px}
.mbottom29{margin-bottom:29px}
.mbottom30{margin-bottom:30px}
.mbottom31{margin-bottom:31px}
.mbottom32{margin-bottom:32px}
.mbottom33{margin-bottom:33px}
.mbottom34{margin-bottom:34px}
.mbottom35{margin-bottom:35px}
.mbottom36{margin-bottom:36px}
.mbottom37{margin-bottom:37px}
.mbottom38{margin-bottom:38px}
.mbottom39{margin-bottom:39px}
.mbottom40{margin-bottom:40px}
.mbottom41{margin-bottom:41px}
.mbottom42{margin-bottom:42px}
.mbottom43{margin-bottom:43px}
.mbottom44{margin-bottom:44px}
.mbottom45{margin-bottom:45px}
.mbottom46{margin-bottom:46px}
.mbottom47{margin-bottom:47px}
.mbottom48{margin-bottom:48px}
.mbottom49{margin-bottom:49px}
.mbottom50{margin-bottom:50px}
.mbottom51{margin-bottom:51px}
.mbottom52{margin-bottom:52px}
.mbottom53{margin-bottom:53px}
.mbottom54{margin-bottom:54px}
.mbottom55{margin-bottom:55px}
.mbottom56{margin-bottom:56px}
.mbottom57{margin-bottom:57px}
.mbottom58{margin-bottom:58px}
.mbottom59{margin-bottom:59px}
.mbottom60{margin-bottom:60px}
.mbottom61{margin-bottom:61px}
.mbottom62{margin-bottom:62px}
.mbottom63{margin-bottom:63px}
.mbottom64{margin-bottom:64px}
.mbottom65{margin-bottom:65px}
.mbottom66{margin-bottom:66px}
.mbottom67{margin-bottom:67px}
.mbottom68{margin-bottom:68px}
.mbottom69{margin-bottom:69px}
.mbottom70{margin-bottom:70px}

.mleft1{margin-left:1px}
.mleft2{margin-left:2px}
.mleft3{margin-left:3px}
.mleft4{margin-left:4px}
.mleft5{margin-left:5px}
.mleft6{margin-left:6px}
.mleft7{margin-left:7px}
.mleft8{margin-left:8px}
.mleft9{margin-left:9px}
.mleft10{margin-left:10px}
.mleft11{margin-left:11px}
.mleft12{margin-left:12px}
.mleft13{margin-left:13px}
.mleft14{margin-left:14px}
.mleft15{margin-left:15px}
.mleft16{margin-left:16px}
.mleft17{margin-left:17px}
.mleft18{margin-left:18px}
.mleft19{margin-left:19px}
.mleft20{margin-left:20px}
.mleft21{margin-left:21px}
.mleft22{margin-left:22px}
.mleft23{margin-left:23px}
.mleft24{margin-left:24px}
.mleft25{margin-left:25px}
.mleft26{margin-left:26px}
.mleft27{margin-left:27px}
.mleft28{margin-left:28px}
.mleft29{margin-left:29px}
.mleft30{margin-left:30px}
.mleft31{margin-left:31px}
.mleft32{margin-left:32px}
.mleft33{margin-left:33px}
.mleft34{margin-left:34px}
.mleft35{margin-left:35px}
.mleft36{margin-left:36px}
.mleft37{margin-left:37px}
.mleft38{margin-left:38px}
.mleft39{margin-left:39px}
.mleft40{margin-left:40px}
.mleft41{margin-left:41px}
.mleft42{margin-left:42px}
.mleft43{margin-left:43px}
.mleft44{margin-left:44px}
.mleft45{margin-left:45px}
.mleft46{margin-left:46px}
.mleft47{margin-left:47px}
.mleft48{margin-left:48px}
.mleft49{margin-left:49px}
.mleft50{margin-left:50px}
.mleft51{margin-left:51px}
.mleft52{margin-left:52px}
.mleft53{margin-left:53px}
.mleft54{margin-left:54px}
.mleft55{margin-left:55px}
.mleft56{margin-left:56px}
.mleft57{margin-left:57px}
.mleft58{margin-left:58px}
.mleft59{margin-left:59px}
.mleft60{margin-left:60px}
.mleft61{margin-left:61px}
.mleft62{margin-left:62px}
.mleft63{margin-left:63px}
.mleft64{margin-left:64px}
.mleft65{margin-left:65px}
.mleft66{margin-left:66px}
.mleft67{margin-left:67px}
.mleft68{margin-left:68px}
.mleft69{margin-left:69px}
.mleft70{margin-left:70px}

.mright1{margin-right:1px}
.mright2{margin-right:2px}
.mright3{margin-right:3px}
.mright4{margin-right:4px}
.mright5{margin-right:5px}
.mright6{margin-right:6px}
.mright7{margin-right:7px}
.mright8{margin-right:8px}
.mright9{margin-right:9px}
.mright10{margin-right:10px}
.mright11{margin-right:11px}
.mright12{margin-right:12px}
.mright13{margin-right:13px}
.mright14{margin-right:14px}
.mright15{margin-right:15px}
.mright16{margin-right:16px}
.mright17{margin-right:17px}
.mright18{margin-right:18px}
.mright19{margin-right:19px}
.mright20{margin-right:20px}
.mright21{margin-right:21px}
.mright22{margin-right:22px}
.mright23{margin-right:23px}
.mright24{margin-right:24px}
.mright25{margin-right:25px}
.mright26{margin-right:26px}
.mright27{margin-right:27px}
.mright28{margin-right:28px}
.mright29{margin-right:29px}
.mright30{margin-right:30px}
.mright31{margin-right:31px}
.mright32{margin-right:32px}
.mright33{margin-right:33px}
.mright34{margin-right:34px}
.mright35{margin-right:35px}
.mright36{margin-right:36px}
.mright37{margin-right:37px}
.mright38{margin-right:38px}
.mright39{margin-right:39px}
.mright40{margin-right:40px}
.mright41{margin-right:41px}
.mright42{margin-right:42px}
.mright43{margin-right:43px}
.mright44{margin-right:44px}
.mright45{margin-right:45px}
.mright46{margin-right:46px}
.mright47{margin-right:47px}
.mright48{margin-right:48px}
.mright49{margin-right:49px}
.mright50{margin-right:50px}
.mright51{margin-right:51px}
.mright52{margin-right:52px}
.mright53{margin-right:53px}
.mright54{margin-right:54px}
.mright55{margin-right:55px}
.mright56{margin-right:56px}
.mright57{margin-right:57px}
.mright58{margin-right:58px}
.mright59{margin-right:59px}
.mright60{margin-right:60px}
.mright61{margin-right:61px}
.mright62{margin-right:62px}
.mright63{margin-right:63px}
.mright64{margin-right:64px}
.mright65{margin-right:65px}
.mright66{margin-right:66px}
.mright67{margin-right:67px}
.mright68{margin-right:68px}
.mright69{margin-right:69px}
.mright70{margin-right:70px}

.pleft1{padding-left:1px}
.pleft2{padding-left:2px}
.pleft3{padding-left:3px}
.pleft4{padding-left:4px}
.pleft5{padding-left:5px}
.pleft6{padding-left:6px}
.pleft7{padding-left:7px}
.pleft8{padding-left:8px}
.pleft9{padding-left:9px}
.pleft10{padding-left:10px}
.pleft11{padding-left:11px}
.pleft12{padding-left:12px}
.pleft13{padding-left:13px}
.pleft14{padding-left:14px}
.pleft15{padding-left:15px}
.pleft16{padding-left:16px}
.pleft17{padding-left:17px}
.pleft18{padding-left:18px}
.pleft19{padding-left:19px}
.pleft20{padding-left:20px}
.pleft21{padding-left:21px}
.pleft22{padding-left:22px}
.pleft23{padding-left:23px}
.pleft24{padding-left:24px}
.pleft25{padding-left:25px}
.pleft26{padding-left:26px}
.pleft27{padding-left:27px}
.pleft28{padding-left:28px}
.pleft29{padding-left:29px}
.pleft30{padding-left:30px}
.pleft31{padding-left:31px}
.pleft32{padding-left:32px}
.pleft33{padding-left:33px}
.pleft34{padding-left:34px}
.pleft35{padding-left:35px}
.pleft36{padding-left:36px}
.pleft37{padding-left:37px}
.pleft38{padding-left:38px}
.pleft39{padding-left:39px}
.pleft40{padding-left:40px}
.pleft41{padding-left:41px}
.pleft42{padding-left:42px}
.pleft43{padding-left:43px}
.pleft44{padding-left:44px}
.pleft45{padding-left:45px}
.pleft46{padding-left:46px}
.pleft47{padding-left:47px}
.pleft48{padding-left:48px}
.pleft49{padding-left:49px}
.pleft50{padding-left:50px}
.pleft51{padding-left:51px}
.pleft52{padding-left:52px}
.pleft53{padding-left:53px}
.pleft54{padding-left:54px}
.pleft55{padding-left:55px}
.pleft56{padding-left:56px}
.pleft57{padding-left:57px}
.pleft58{padding-left:58px}
.pleft59{padding-left:59px}
.pleft60{padding-left:60px}
.pleft61{padding-left:61px}
.pleft62{padding-left:62px}
.pleft63{padding-left:63px}
.pleft64{padding-left:64px}
.pleft65{padding-left:65px}
.pleft66{padding-left:66px}
.pleft67{padding-left:67px}
.pleft68{padding-left:68px}
.pleft69{padding-left:69px}
.pleft70{padding-left:70px}

.pright1{padding-right:1px}
.pright2{padding-right:2px}
.pright3{padding-right:3px}
.pright4{padding-right:4px}
.pright5{padding-right:5px}
.pright6{padding-right:6px}
.pright7{padding-right:7px}
.pright8{padding-right:8px}
.pright9{padding-right:9px}
.pright10{padding-right:10px}
.pright11{padding-right:11px}
.pright12{padding-right:12px}
.pright13{padding-right:13px}
.pright14{padding-right:14px}
.pright15{padding-right:15px}
.pright16{padding-right:16px}
.pright17{padding-right:17px}
.pright18{padding-right:18px}
.pright19{padding-right:19px}
.pright20{padding-right:20px}
.pright21{padding-right:21px}
.pright22{padding-right:22px}
.pright23{padding-right:23px}
.pright24{padding-right:24px}
.pright25{padding-right:25px}
.pright26{padding-right:26px}
.pright27{padding-right:27px}
.pright28{padding-right:28px}
.pright29{padding-right:29px}
.pright30{padding-right:30px}
.pright31{padding-right:31px}
.pright32{padding-right:32px}
.pright33{padding-right:33px}
.pright34{padding-right:34px}
.pright35{padding-right:35px}
.pright36{padding-right:36px}
.pright37{padding-right:37px}
.pright38{padding-right:38px}
.pright39{padding-right:39px}
.pright40{padding-right:40px}
.pright41{padding-right:41px}
.pright42{padding-right:42px}
.pright43{padding-right:43px}
.pright44{padding-right:44px}
.pright45{padding-right:45px}
.pright46{padding-right:46px}
.pright47{padding-right:47px}
.pright48{padding-right:48px}
.pright49{padding-right:49px}
.pright50{padding-right:50px}
.pright51{padding-right:51px}
.pright52{padding-right:52px}
.pright53{padding-right:53px}
.pright54{padding-right:54px}
.pright55{padding-right:55px}
.pright56{padding-right:56px}
.pright57{padding-right:57px}
.pright58{padding-right:58px}
.pright59{padding-right:59px}
.pright60{padding-right:60px}
.pright61{padding-right:61px}
.pright62{padding-right:62px}
.pright63{padding-right:63px}
.pright64{padding-right:64px}
.pright65{padding-right:65px}
.pright66{padding-right:66px}
.pright67{padding-right:67px}
.pright68{padding-right:68px}
.pright69{padding-right:69px}
.pright70{padding-right:70px}

.font1{font-size:1px}
.font2{font-size:2px}
.font3{font-size:3px}
.font4{font-size:4px}
.font5{font-size:5px}
.font6{font-size:6px}
.font7{font-size:7px}
.font8{font-size:8px}
.font9{font-size:9px}
.font10{font-size:10px}
.font11{font-size:11px}
.font12{font-size:12px}
.font13{font-size:13px}
.font14{font-size:14px}
.font15{font-size:15px}
.font16{font-size:16px}
.font17{font-size:17px}
.font18{font-size:18px}
.font19{font-size:19px}
.font20{font-size:20px}
.font21{font-size:21px}
.font22{font-size:22px}
.font23{font-size:23px}
.font24{font-size:24px}
.font25{font-size:25px}
.font26{font-size:26px}
.font27{font-size:27px}
.font28{font-size:28px}
.font29{font-size:29px}
.font30{font-size:30px}
.font31{font-size:31px}
.font32{font-size:32px}
.font33{font-size:33px}
.font34{font-size:34px}
.font35{font-size:35px}
.font36{font-size:36px}
.font37{font-size:37px}
.font38{font-size:38px}
.font39{font-size:39px}
.font40{font-size:40px}
.font41{font-size:41px}
.font42{font-size:42px}
.font43{font-size:43px}
.font44{font-size:44px}
.font45{font-size:45px}
.font46{font-size:46px}
.font47{font-size:47px}
.font48{font-size:48px}
.font49{font-size:49px}
.font50{font-size:50px}
.font51{font-size:51px}
.font52{font-size:52px}
.font53{font-size:53px}
.font54{font-size:54px}
.font55{font-size:55px}
.font56{font-size:56px}
.font57{font-size:57px}
.font58{font-size:58px}
.font59{font-size:59px}
.font60{font-size:60px}
.font61{font-size:61px}
.font62{font-size:62px}
.font63{font-size:63px}
.font64{font-size:64px}
.font65{font-size:65px}
.font66{font-size:66px}
.font67{font-size:67px}
.font68{font-size:68px}
.font69{font-size:69px}
.font70{font-size:70px}

/*
http://bigcolorpicker.com/#505865
https://docs.google.com/spreadsheets/d/1uOHO7akWZH1Dj935AhMz0zYPjlkkQayHy7xen3oP9T4/edit#gid=0
*/
.bc0{background-color:#F9FAFB}
.bc1{background-color:#F2F3F5}
.bc2{background-color:#EBEDF0}
.bc3{background-color:#E0E3E8}
.bc4{background-color:#D6DAE0}
.bc5{background-color:#CDD2D9}
.bc6{background-color:#C3C8D1}
.bc7{background-color:#BABFC9}
.bc8{background-color:#B1B7C1}
.bc9{background-color:#A8AFBA}
.bc10{background-color:#9FA6B2}
.bc11{background-color:#969EAA}
.bc12{background-color:#8E96A3}
.bc13{background-color:#868E9B}
.bc14{background-color:#7D8593}
.bc15{background-color:#767E8C}
.bc16{background-color:#6E7684}
.bc17{background-color:#666E7C}
.bc18{background-color:#5E6674}
.bc19{background-color:#575F6D}
.bc20{background-color:#505865}

.bc40{background-color:#000000}

.c0{color:#F9FAFB}
.c1{color:#F2F3F5}
.c2{color:#EBEDF0}
.c3{color:#E0E3E8}
.c4{color:#D6DAE0}
.c5{color:#CDD2D9}
.c6{color:#C3C8D1}
.c7{color:#BABFC9}
.c8{color:#B1B7C1}
.c9{color:#A8AFBA}
.c10{color:#9FA6B2}
.c11{color:#969EAA}
.c12{color:#8E96A3}
.c13{color:#868E9B}
.c14{color:#7D8593}
.c15{color:#767E8C}
.c16{color:#6E7684}
.c17{color:#666E7C}
.c18{color:#5E6674}
.c19{color:#575F6D}
.c20{color:#505865}

.bor0{border:1px solid #F9FAFB}
.bor1{border:1px solid #F2F3F5}
.bor2{border:1px solid #EBEDF0}
.bor3{border:1px solid #E0E3E8}
.bor4{border:1px solid #D6DAE0}
.bor5{border:1px solid #CDD2D9}
.bor6{border:1px solid #C3C8D1}
.bor7{border:1px solid #BABFC9}
.bor8{border:1px solid #B1B7C1}
.bor9{border:1px solid #A8AFBA}
.bor10{border:1px solid #9FA6B2}
.bor11{border:1px solid #969EAA}
.bor12{border:1px solid #8E96A3}
.bor13{border:1px solid #868E9B}
.bor14{border:1px solid #7D8593}
.bor15{border:1px solid #767E8C}
.bor16{border:1px solid #6E7684}
.bor17{border:1px solid #666E7C}
.bor18{border:1px solid #5E6674}
.bor19{border:1px solid #575F6D}
.bor20{border:1px solid #505865}

.hover17:hover {background-color: #666E7C !important; }
